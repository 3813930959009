import { Dialog, Notify } from "vant";
export default {
  name: "Login",
  data() {
    return {
      openid: "",
      phoneNo: "",
      code: "",

      isSmsSend: false,
      sendBtnText: "发送验证码",
      timer: null,
      counter: 60,
    };
  },
  mounted() {
    document.title = "登录";
    // 解析授权回调的code，并获取openid
    const code = new URLSearchParams(window.location.search).get("code");
    if (code) {
      this.getOpenid(code);
    } else {
      this.auth();
    }
  },
  methods: {
    async onCode() {
      if (this.phoneNo == "" || this.phoneNo == null) {
        Notify({ type: "warning", message: "请输入手机号码" });
        return;
      }
      if (this.phoneNo.length !== 11) {
        Notify({ type: "warning", message: "请输入正确的手机号" });
        return;
      }

      await this.$axios({
        method: "post",
        url: "/api/mobile/code",
        data: {
          phoneNo: this.phoneNo,
        },
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.isSmsSend = true;
            this.countDown();
            Notify({ type: "success", message: "发送成功" });
          } else {
            Notify({ type: "warning", message: res.data.message });
          }
        })
        .catch((error) => {
          Notify({ type: "warning", message: error });
        });
    },
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `(${this.counter}秒)后重新发送`;
        this.counter--;
        if (this.counter < 0) {
          this.reset();
        }
      }, 1000);
    },
    reset() {
      this.isSmsSend = false;
      this.sendBtnText = "点击发送验证码";
      if (this.timer) {
        clearInterval(this.timer);
        this.counter = 60;
        this.timer = null;
      }
    },
    auth() {
      // 构造授权链接
      const redirectUri = encodeURIComponent(
        `${window.location.origin}/#/login`
      );
      const scope = "snsapi_userinfo";
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`;

      // 跳转到授权链接
      window.location.href = url;
    },
    async getOpenid(code) {
      await this.$axios
        .get(`/api/mobile/user/wechat/openid/${code}`)
        .then((res) => {
          if (res.data.code === 200) {
            this.openid = res.data.data;

            // search user
            this.$axios({
              method: "post",
              url: "/api/mobile/login/openId",
              data: {
                openId: this.openid,
              },
            }).then((res1) => {
              if (res1.data.code === 200) {
                localStorage.setItem(
                  "userObj",
                  JSON.stringify(res1.data.data.user)
                );
                sessionStorage.setItem("z-token", res1.data.data.jwtToken);
                localStorage.setItem("z-token", res1.data.data.jwtToken);

                this.checkUser();
              }
            });
          }
        });
    },
    onSubmit(values) {
      // console.log(values);
      if (values.phoneNo == "" || values.phoneNo == null) {
        Notify({ type: "warning", message: "请输入手机号码" });
        return;
      }
      if (values.code == "" || values.code == null) {
        Notify({ type: "warning", message: "请输入验证码" });
        return;
      }
      this.$axios({
        method: "post",
        url: "/api/mobile/login/code",
        data: values,
      }).then((res) => {
        if (res.data.code === 200) {
          localStorage.setItem("userObj", JSON.stringify(res.data.data.user));
          sessionStorage.setItem("z-token", res.data.data.jwtToken);
          localStorage.setItem("z-token", res.data.data.jwtToken);

          if (this.openid !== null && this.openid !== "") {
            // update openid
            this.$axios({
              method: "post",
              url: "/api/mobile/user/update",
              data: {
                openId: this.openid,
                uuid: res.data.data.user.uuid,
              },
            }).then((res) => {
              console.log(res);
              this.checkUser();
            });
            this.$axios({
              method: "get",
              url: "/api/mobile/user/status",
            }).then((res) => {
              console.log(res, "更新签约状态");
            });
          } else {
            this.checkUser();
            this.$axios({
              method: "get",
              url: "/api/mobile/user/status",
            }).then((res) => {
              console.log(res, "更新签约状态");
            });
          }
        } else {
          Dialog.alert({
            message: res.data.message,
            theme: "round-button",
            confirmButtonColor: "#1a89fa",
          }).then(() => {
            // on close
          });
        }
      });
    },
    checkUser() {
      // check task
      this.$axios.get("/api/mobile/task/isTask").then((resT) => {
        if (resT.data.code === 200) {
          const task = resT.data.data;
          if (task !== null && task.length > 0) {
            console.log("task[0].uuid", task[0].uuid);
            this.$axios
              .get(`/api/mobile/task/acceptTask/${task[0].uuid}`)
              .then((res1) => {
                console.log("res1.data", res1.data);
                if (res1.data.code === 200) {
                  const gotoUrl = res1.data.data;
                  window.location.href = gotoUrl;
                } else {
                  Dialog.alert({
                    message: res1.data.message,
                    theme: "round-button",
                    confirmButtonColor: "#1a89fa",
                  }).then(() => {
                    // on close
                  });
                }
              });
          } else {
            // this.$router.push("/info");
            this.$router.push("/home");
          }
        }
      });
    },
  },
};
